<template>
  <div style="font-size:75%;border: 1px solid gray;margin-top:7%;padding:2%;text-align:justify;">
    ※配送日について<br>
    ・13時までの注文で当日配送を選択できます。<br>
    ・当日を含め1週間後まで選択できます。<br>
    ・店舗の休業日及び土日祝日は選択できません。選択肢に表示されない日付は店舗の休業日となります。<br>
    ※商品の配送について<br>
    ・選択した配送日の14時～18時に指定した配送先にお届けいたします。<br>
    ・交通状況によっては配送時間に影響が生じる可能性があります。<br>
    ・再配達を含め18時までに商品の受け取りができない場合、18時以降に購入店舗での受取となります。<br>
    ※宅配について<br>
    ・選択した配送日の14時～18時に指定した配送先にお届けいたします。<br>
    ・配送日18時までに商品をお受け取りいただけない場合、商品特性上・品質管理上 販売店へ商品を戻させていただきます。
    販売店でのお荷物引き取りの際は必ず販売店へご連絡の上、｢ご不在連絡票｣と「運転免許証」などご本人とご住所が確認できる証明書をご持参ください。<br>
    ・上記時間帯に対面での受取が困難な方は「置き配」または「専用ロッカー」を選択してください。<br>
    ※置き配について<br>
    ・置き配をご希望の方は、配送先選択画面の「置き配を希望する」にチェックしてください。<br>
    ・食品衛生上不適切な商品は置き配選択できません。<br>
    ・配送員がお届けできるご住所のみ指定可能です。
    （マンションのオートロックなど配送員が玄関先まで訪問できないご住所の方は置き配を選択しないでください）<br>
    ・返却が必要な保冷バックは、配送日翌日に回収します。置き配受取りと同じ場所へご返却ください。
    （配送日が金曜日の場合は翌週の第1営業日に回収します）<br>
    ※専用ロッカーについて<br>
    ・Tajimingo専用ロッカーへの配達が可能です。<br>
    ・ロッカーへの配送が完了すると、登録メールアドレス宛にロッカーを開くための解錠コード（ロッカーキー）が送信されます。<br>
    ・受信制限等の設定をされているお客様は「tajimingo.com」からのメールを受信できるように設定お願いします。<br>
    ・ロッカーキーが届きましたら、配送先に指定したロッカーで商品を受け取ることができます。<br>
    ・商品の保管期間はおよそ24時間です。
    保管期間を過ぎると配送商品は購入店舗に回収されますので、商品のお受け取りについては購入店舗にお問い合わせください。<br>
    ※ロッカーの使用方法について<br>
    &nbsp;1.ロッカーの操作パネルをタッチ<br>
    &nbsp;2.「受取」をタッチ<br>
    &nbsp;3.QRコードをかざす、またはロッカーキーを入力<br>
    &nbsp;4.配達商品が入ったボックスの扉が自動的に全て開く <br>
    &nbsp;（受取商品が一つのボックスに収まりきらない場合は、複数のボックスに配送され、すべてのボックスが同時に開きます）<br>
    &nbsp;5.開いたボックスから商品をすべて取り出し、扉を閉める<br>
    &nbsp;（保冷バッグはボックス内に戻し扉を閉めます）<br>
    &nbsp;6.操作パネルで「終了」をタッチ<br>
    &nbsp;（別の受取商品がある場合は、「続ける」をタッチします）<br>
    ・受取完了後、30分間は、同じ操作で扉を開扉可能です。
    誤って扉を閉じてしまった、受取忘れがあった等、再度開扉したい場合は、再度操作してください。
    30分経過すると、開扉できなくなります。<br>
    ・一定期間、受け取られていない商品については、回収後、購入店舗に返却されます。<br>
  </div>
</template>

<script>
export default {
  name: "ShoppingRules"
}
</script>

<style>

</style>