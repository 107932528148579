<template>
  <div>

    <v-container>
      <!-- <Sidebar /> -->
      <HeaderPage />
      <v-stepper
        v-model="stepper"
        flat
        style="margin-top:50px; margin-bottom: 2em;width: 100%;"
      >
        <v-stepper-header>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step :complete="stepper == 1" step="1" color="#ff0090">
            </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100xp">
              <small>{{ $t("message.label-step-checkout") }}</small>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step step="2" color="#ff0090"> </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100px">
              <small>{{ $t("message.order-process") }}</small>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step step="3" color="#ff0090"> </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100px">
              <small>{{ $t("message.label-step-success") }}</small>
            </div>
          </div>
        </v-stepper-header>
        <v-stepper-content step="2" style="padding:0;margin-top:5%;">
          <div class="d-flex flex-row mt-3">
            <div
              style="width:7px; height:100%;background-color:#ff0090;margin-right:2%;margin-bottom:2%"
            >
              &nbsp;
            </div>
            <div>{{ $t("message.delivery-address-dates") }}</div>
          </div>
          <div class="d-flex flex-column" style="border: 1px solid grey">
            <span class="pa-2 font-class">{{
              $t("message.delivery-address")
            }}</span>
            <div v-if="isLoading">
              <div v-for="i in 2" :key="i">
                <SkeletonDelivery />
              </div>
            </div>
            <div v-else>
              <div
                class="card-radio"
                v-for="(address, i) in destination"
                :key="i"
              >
                <label>
                  <input
                    type="radio"
                    v-model="selectAddress"
                    :value="address"
                    @change="getValue(address)"
                    name="address"
                    class="card-input-element radio-custom"
                    required
                    :disabled="address.is_used == 0  || address.total_available == 0 || (get_isLocker == 0 && address.locker_id != null)"
                  />

                  <div class="card-input">
                    <v-row align="center">
                      <v-col cols="12">
                        <div
                          v-if="address.customer_id || address.prefecture || address.postal_code"
                          style="display: flex; flex-direction: column; justify-content: center;"
                        >
                          <!-- <span
                            :class="
                              address.is_used == 1
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >{{ $t("message.address-regis") }} {{ i + 1 }}
                          </span> -->
                          <span
                          v-if="address.address_name"
                            :class="
                              address.is_used == 1
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >
                            {{ address.address_name }}</span
                          >
                          <span
                            :class="
                              address.is_used == 1
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >〒{{
                              formatPostalcode(address.postal_code)
                            }}
                            {{ address.prefecture }} {{ address.city }}
                            {{ address.district }} {{ address.ward }}
                            {{ address.street_address }} {{ address.building }}
                          </span>
                        </div>
                        <div
                          v-else
                          style="display: flex; flex-direction: column; justify-content: center;"
                        >
                          <!-- <span :class="
                              address.total_available != 0
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >{{ $t("message.locker-id") }}{{ address.locker_id }}
                          </span> -->
                          <span :class="
                              address.total_available != 0 && (get_isLocker == 1 && address.locker_id != null)
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >
                            <!-- {{ $t("message.label-name") }}: -->
                            <span style="color: #ff0090;">【専用ロッカー】</span>
                            {{ address.locker_name }}</span
                          >
                          <span :class="
                              address.total_available != 0 && (get_isLocker == 1 && address.locker_id != null)
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >
                            {{ address.location }}
                          </span>
                          <!-- <span :class="
                              address.total_available != 0
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >{{ $t("message.quist-locker-id") }}:
                            {{ address.quist_locker_id }}
                          </span>
                          <span :class="
                              address.total_available != 0
                                ? 'font-class'
                                : 'disable-style'
                            "
                            >{{ $t("message.total-locker") }}: {{ address.total_available }}
                          </span> -->
                        </div>
                        <!-- <span v-if="">{{ $t("message.label-locker-title") }}</span> -->
                      </v-col>
                    </v-row>
                  </div>
                  <p
                    class=" red--text text-start ms-4"
                    style="font-size: 10px"
                    v-if="address.is_used == 0"
                  >
                    {{ $t("message.err-msg-address-cantUse") }}
                  </p>
                </label>
                <!-- <div class="ps-5" style="color: #ff0090; font-weight: bold;margin-top: 2em;" v-if="i == addressList.length - 1">{{ $t("message.label-locker-title") }}</div> -->
              </div>
            </div>
            <div class="mt-2 mx-6">
              <p style="color: #ff0090;font-size:75%;text-align:justify;">専用ロッカーをご利用の場合、商品の受取時にエコバッグをご持参ください。</p>
            </div>
            <div
              class="d-flex flex-column align-center"
              style="margin-top:3.5em;margin-bottom:2em"
            >
              <v-row
                ><v-btn
                  style="margin-bottom:2%; border: 2px solid #ff0090; background-color:transparent"
                  outlined
                  small
                  @click.prevent="$router.push('/address/add')"
                >
                  <span
                    class="font-class black--text"
                    style="text-transform: none"
                    >{{ $t("message.register-shipping-address") }}</span
                  >
                </v-btn></v-row
              >
            </div>
          </div>
          <ShoppingRules/>
          <div style="border: 1px solid gray;margin-top:7%;padding:2%">
            <span class="font-class">{{ $t("message.delivery-date") }}</span>
            <v-row class="mt-3">
              <v-col>
                <FormulateInput
                  v-model="valDate"
                  :options="deliveryDate"
                  name="deliveryDate"
                  type="select"
                  :placeholder="title_deliveryDate"
                  :label="title_deliveryDate"
                  @change="getValue(valDate)"
                />
              </v-col>
              <!-- <v-col>
                <FormulateInput
                  v-model="valTime"
                  :options="deliveryTime"
                  type="select"
                  :placeholder="title_deliveryTime"
                  :label="title_deliveryTime"
                />
              </v-col> -->
            </v-row>
            <div class="mt-2 mb-5">
              <span class="font-class"><strong class="mr-5">{{ $t("message.title-delivery-time") }}</strong>14時～18時</span>
            </div>
          </div>
          <div style="border: 1px solid gray;margin-top:7%;padding:2%">
            <div class="">
              <span class="font-class" style="text-align: justify;">{{ $t("message.label-notes1") }}</span>
            </div>
            <div>
              <v-checkbox
                :disabled="isDelivery != 1"
                v-model="checkbox"
                false-value="0"
                true-value="1"
              >
              <template v-slot:label>
                <span class="font-class"><strong>置き配を希望する</strong></span>
              </template>
              </v-checkbox>
            </div>
            <div class="mt-3">
              <span class="font-class"><strong>{{ $t("message.label-notes") }}</strong></span>
            </div>
            <v-textarea outlined placeholder="" v-model="notes" />
          </div>
          <div v-if="isDeliveryFee == 1" style="border: 1px solid gray; margin-top: 7%; padding: 2%;">
            <div class="">
              <span class="font-class" style="text-align: justify;">カムカムデリバリーGO（コロナ対策買い物支援事業）の対象者の方は該当する項目を選択してください。</span>
            </div>
            <div class="mt-3">
              <span class="font-class"><strong>カムカムデリバリーGO</strong></span>
            </div>
            <v-select
              v-model="data_type_quarantine"
              :items="quarantine_select"
              item-text="text"
              item-value="id"
              outlined
              return-object
            ></v-select>
          </div>
          <v-col style="margin-bottom: 3rem">
            <v-row justify="center" style="">
              <v-col cols="6" style="">
                <div>
                  <v-btn
                    @click="$router.go(-1)"
                    width="100%"
                    style="border: 2px solid gray; background-color:#808080;color:white"
                    outlined
                    ><span class="font-class" style="text-transform: none;">{{
                      $t("message.back")
                    }}</span></v-btn
                  >
                </div>
              </v-col>
              <v-col cols="6" style="">

                <v-btn
                  @click.prevent="confirmOrder()"
                  width="100%"
                  :disabled="isValid"
                  style="border: 2px solid #ff0090; background-color:#ff0090;text-transform: none;"
                  outlined
                  class="font-class"
                  :loading="isLoadingBtn"
                  >
                  <span class="" style="color: white">
                    {{ $t("message.label-confirm-order") }}
                  </span>
                  </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-stepper-content>
      </v-stepper>
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
// import Sidebar from "@/components/Sidebar.vue";
import Sidebar from '../components/developmentv2/Sidebar.vue'
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import SkeletonDelivery from "@/components/skeleton/SkeletonDelivery.vue";
import ShoppingRules from "@/components/ShoppingRules.vue";
import Swal from "sweetalert2";
import { mdiArrowLeft } from "@mdi/js";
import postalcode from "../data/postalcode.json";

export default {
  name: "SelectLocker",
  components: {
    Sidebar,
    SkeletonDelivery,
    ShoppingRules,
    HeaderPage
  },
  data() {
    return {
      valTime: null,
      valDate: null,
      iconArrowLeft: mdiArrowLeft,
      isFormValid: false,
      selectAddress: null,
      selected: "radio-1",
      clickVal: null,
      isCheck: false,
      stepper: 2,
      notes: "",
      data_type_quarantine: { id: "0", text: "-" },
      delivery: [],
      itemConfirm: [],
      isLoadingBtn: false,
      isLoading: true,
      postalData: postalcode,
      destination: [],
      checkbox: "0",
      isDeliveryFee: null
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
    this.getDeliveryFee()
  },
  computed: {
    isDelivery() {
      let shipping_type;
      let carts = JSON.parse(localStorage.getItem("cartItems"));
      carts.forEach((el) => {
        shipping_type = el.is_delivery
      })
      return shipping_type
    },
    get_isLocker() {
      let locker_value;
      let carts = JSON.parse(localStorage.getItem("cartItems"));
      carts.forEach((val) => {
        locker_value = val.is_locker
      })
      return locker_value
    },
    quarantine_select() {
      return [
        { id: "0", text: "-" },
        { id: "1", text: "自宅療養者、自宅待機者宅" },
        { id: "2", text: "福祉医療介護事業所" }
      ]
    },
    title_deliveryTime() {
      return this.$t("message.title-delivery-time");
    },
    title_deliveryDate() {
      return this.$t("message.title-delivery-date");
    },
    addressList() {
      let addressList = this.$store.state.address_module.addressList;
      let postal = this.postalData;
      for (let i = 0; i < addressList.length; i++) {
        // for (let j = 0; j < postal.length; j++) {
        //   if (
        //     !addressList[i].locker_id &&
        //     addressList[i].postal_code !== postal[j].value
        //   ) {
        //     addressList[i].is_used = 0;
        //   } else if (addressList[i].postal_code == postal[j].value) {
        //     addressList[i].is_used = 1;
        //     break;
        //   }
        // }
        addressList[i].is_used = (addressList[i].postal_code.substr(0, 3) == '507') ? 1 : 0;
      }
      return addressList;
    },
    lockerList() {
      return this.$store.state.address_module.lockerResult;
    },
    deliveryTime() {
      const a = this.$store.state.order_module.deliveryTime.map((item) => {
        return item.slice(0, 2) + "時頃";
      });
      return a;
    },
    deliveryDate() {
      return this.$store.state.order_module.deliveryDate;
    },
    isValid() {
      if (this.valDate && this.selectAddress) {
        return false;
      }
      return true;
    },
    // isDeliveryFee() {
    //   console.log(this.$store.state.order_module.getDeliveryFee, 'deliveryfee');
    //   return this.$store.state.order_module.getDeliveryFee
    // }
  },

  created() {
    // NOTE: hide it to handle fetch one time only
    // this.dispatchAddress();
    // this.dispatchLocker();
    this.deliveryAddressDate();
    // this.getPostalWhiteList();
    this.mergingDestination();
    
  },
  methods: {
    
    async getDeliveryFee() {
      await this.$store.dispatch("order_module/fetchGetDeliveryFee")
      let is_delivery_fee =  this.$store.state.order_module.getDeliveryFee
      this.isDeliveryFee = is_delivery_fee
    },

    async mergingDestination() {
      this.isLoading = true;

      await this.dispatchAddress();
      await this.dispatchLocker();

      this.isLoading = false;

      let addressList = this.addressList;
      let lockerList = this.lockerList;
      let resultLength = addressList.length + lockerList.length;
      if (resultLength <= this.destination.length) {
        return;
      } else if (resultLength == this.destination.length) {
        return;
      } else {
        return this.destination.push(...addressList, ...lockerList);
      }
      
    },
    getPostalWhiteList() {
      this.postalData = postalcode;
    },
    formatPostalcode(postalcode) {
      let newPostcode = postalcode.slice(0, 3) + "-" + postalcode.slice(3);
      return newPostcode;
    },
    deliveryAddressDate() {
      let store_id;
      let carts = JSON.parse(localStorage.getItem("cartItems"));
      carts.forEach((val) => {
        store_id = val.store_id;
      });

      this.$store.dispatch("order_module/deliveryAddressDate", {
        store_id: store_id,
      });
    },
    async dispatchLocker() {
      // this.isLoading = true;
      let res = await this.$store.dispatch("address_module/fetchLocker");
      // this.isLoading = false;
      return res;
    },
    async dispatchAddress() {
      // this.isLoading = true;
      if (!localStorage.getItem("cartItems")) {
        return this.$router.push("carts");
      }

      let data = await this.$store.dispatch("address_module/fetchAddress");
      // this.isLoading = false;

      return data;
    },
    getValue(addressVal) {
      this.clickVal = addressVal;
      this.selected = addressVal;
    },
    confirmOrder() {
      if (!this.selectAddress) {
        Swal.fire("Please select the address first");
        return false;
      }
      this.isLoadingBtn = true;

      this.delivery = this.selectAddress;
      this.itemConfirm = JSON.parse(localStorage.getItem("cartItems"));

      return this.checkoutPayment();
    },
    async checkoutPayment() {
      let cart = [];
      let price_orders, store_id, store_name;
      this.itemConfirm.forEach((val) => {
        price_orders = val.total_amount_w_delivery_fee_per_store;
        store_id = val.store_id;
        store_name = val.store_name

        cart.push({
          delivery_fee: val.delivery_fee_display,
          total_weight_order: 0,
          store_id: val.store_id,
          total_item_price: val.subtotal,
          total_order_price: val.total_amount_w_delivery_fee_per_store,
          total_tax: val.subtotal_tax_amount,
        });
      });
      let getNotes = localStorage.getItem("notes");
      let setNotes;
      if(getNotes === "" || getNotes === null) {
        setNotes = this.notes;
      } else {
        setNotes = getNotes.concat('----------', '\n', this.notes);
      }
      let payload;
      payload = {
        order_type: this.data_type_quarantine.id,
        notes: setNotes,
        order_shipping_type: parseInt(this.checkbox),
        store_id: store_id,
        customer_address_id:
          this.delivery.id == undefined
            ? ""
            : this.delivery.id,
        locker_id:
          this.delivery.locker_id == undefined ? "" : this.delivery.locker_id,
        price_orders: price_orders,
        redirect_url_success:
          window.location.origin + process.env.BASE_URL + "success",
        redirect_url_cancel:
          window.location.origin + process.env.BASE_URL + "carts",
        cart: cart,
        delivery_date: this.valDate,
      };

      let resp = await this.$store.dispatch(
        "carts_module/confirmOrder",
        payload
      );

      if (resp.status == 200) {
        this.$gtag.event("payment-click", {
          event_category: "Enhanced Ecommerce",
          event_label: store_name,
          value: price_orders,
        });

        this.invoice_no = resp.invoice_no;
        localStorage.setItem("invoice", resp.invoice_no);
        this.isLoadingBtn = false;
        window.open(resp.payment_url, "_self");
        localStorage.removeItem("notes");
      }
      this.isLoadingBtn = false;
    },
  },
};
</script>

<style>
.v-stepper__header {
  box-shadow: none !important;
}
.font-class {
  font-size: 14px !important;
}
.disable-style {
  color: grey;
  font-size: 14px;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  height: 25px;
}
.add-address {
  display: flex;
}
.button-class {
  position: fixed !important ;
  width: 100% !important;
  height: 50px !important;
  bottom: 0px;
  color: white !important;
  border-radius: 0px !important;
  box-shadow: 0px !important;
}
.v-btn__content {
  /* color: white !important; */
}
.card-radio {
  /* background-color: #fff; */
  border-radius: 10px;
  position: relative;
}
.radio-btn {
  position: fixed !important ;
  margin: 0px !important;
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

label {
  width: 100%;
}

[type="radio"]:checked + label:after {
  background-color: red;
  border: 2px solid red;
}

.card-input-element {
  font-size: inherit;
  margin-right: 10px;
  position: absolute;
  left: 20px;
  top: 2em;
  height: 20px;
  width: 20px;
}

.card-input {
  margin: 10px;
  padding: 00px;
  margin-left: 3em;
}

.card-input:hover {
  cursor: pointer;
}
.row-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  /* height: 30px; */
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}
.v-stepper__header {
  box-shadow: none;
}
</style>
