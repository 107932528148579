<template>
  <v-skeleton-loader v-bind="attrs" type="table-heading, list-item-three-line"></v-skeleton-loader>
</template>

<script>
export default {
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 0,
      },
    };
  },
};
</script>

<style></style>
